// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-actualites-dato-cms-article-slug-js": () => import("./../../../src/pages/actualites/{datoCmsArticle.slug}.js" /* webpackChunkName: "component---src-pages-actualites-dato-cms-article-slug-js" */),
  "component---src-pages-actualites-index-js": () => import("./../../../src/pages/actualites/index.js" /* webpackChunkName: "component---src-pages-actualites-index-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-expertises-dato-cms-specialty-parent-right-slug-dato-cms-specialty-slug-js": () => import("./../../../src/pages/expertises/{datoCmsSpecialty.parentRight__slug}/{datoCmsSpecialty.slug}.js" /* webpackChunkName: "component---src-pages-expertises-dato-cms-specialty-parent-right-slug-dato-cms-specialty-slug-js" */),
  "component---src-pages-expertises-index-js": () => import("./../../../src/pages/expertises/index.js" /* webpackChunkName: "component---src-pages-expertises-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-office-notarial-js": () => import("./../../../src/pages/office-notarial.js" /* webpackChunkName: "component---src-pages-office-notarial-js" */)
}

